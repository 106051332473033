/* Component - Scroller
=================================== */
carousel-slider {
  position: relative;
  display: block;
}

@media (min-width: 990px) {
  carousel-slider:hover .pill-nav-slider-inline-nav {
    opacity: 1;
    z-index: 100;
  }
}

@media (max-width: 989px) {
  .scroller {
    overflow: hidden;
  }
}

.scroller.scroller-desktop {
  overflow: hidden;
}

@media (max-width: 989px) {
  .scroller-inner {
    padding-bottom: 20px;
    margin-bottom: -20px;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
  }

  .scroller-inner .grid {
    flex-flow: row nowrap;
    padding: 1px 1px 2px 1px;
  }

  .scroller-inner .grid:not(.grid-2-col-tablet) .grid-item {
    width: calc(33.333% + 2rem);
    max-width: calc(33.333% + 2rem);
    scroll-snap-align: center;
  }
}

@media (max-width: 749px) {
  .scroller-inner .grid .grid-item:not(:only-of-type) {
    width: 85%;
    max-width: 85%;
  }

  .scroller-inner .collage {
    display: flex;
    flex-flow: row nowrap;
  }

  .scroller-inner .collage .card-overlay:not(:only-of-type) {
    width: 85%;
    max-width: 85%;
    flex: none;
    scroll-snap-align: center;
  }
}

.grid.grid-carousel::after {
  content: "";
  display: none;
}

@media (min-width: 990px) {
  .grid.grid-carousel {
    column-gap: 0;
    row-gap: 0;
    flex-wrap: nowrap;
    width: 100%;
    overflow: hidden;
  }

  .grid.grid-carousel::after {
    content: "flickity";
    display: none;
  }

  .grid.grid-carousel .grid-item {
    margin-right: var(--grid-desktop-horizontal-spacing);
    min-height: 100%;
    display: flex;
    transform-style: preserve-3d;
    padding-bottom: 0.3rem;
  }

  .grid.grid-carousel .grid-item > div,
  .grid.grid-carousel .grid-item > blockquote,
  .grid.grid-carousel .grid-item > product-card {
    height: auto;
    width: 100%;
    overflow: visible;
  }
}

@media (max-width: 989px) {
  .grid.grid-carousel {
    display: flex !important;
  }
}

@media (min-width: 1200px) {
  .grid-compact.grid-carousel .grid-item {
    margin-right: 8px;
  }
}
