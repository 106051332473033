/* Section - Features (Media Icon Items)
========================================= */
@media (min-width: 990px) {
  .media-icon-items-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--grid-desktop-horizontal-spacing);
  }

  .media-icon-items-grid.media-icon-items-grid-1 {
    grid-template-columns: 1fr;
  }

  .media-icon-items-grid.media-icon-items-grid-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .media-icon-items-grid.media-icon-items-grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.media-icon-item {
  font-size: calc(var(--font-body-scale) * 1.4rem);
  display: flex;
  flex-direction: row;
  line-height: calc(1 + 0.35 / var(--font-body-scale));
  grid-column-gap: 1rem;
  margin-top: -1px;
  padding: 2rem;
  height: auto;
  align-items: initial;
  border-radius: var(--border-radius-base);
  background-color: rgb(var(--color-background));
  position: relative;
  box-shadow: inset 0 0 0 1px var(--color-border), 0 3px 0 var(--color-box-shadow);
}

.media-icon-item-align-center {
  align-items: center;
}

.media-icon-item:first-child {
  border-top-left-radius: var(--border-radius-base);
  border-top-right-radius: var(--border-radius-base);
}

.media-icon-item:last-child {
  border-bottom-left-radius: var(--border-radius-base);
  border-bottom-right-radius: var(--border-radius-base);
}

.media-icon-item-subtitle p {
  margin: 0;
}

@media (min-width: 750px) {
  .media-icon-item {
    border-radius: var(--border-radius-base);
  }
}

.media-icon-item-icon {
  flex: none;
  width: 4rem;
  height: 4rem;
  line-height: 1;
}

.media-icon-item-icon svg {
  width: 100%;
  height: 100%;
  position: relative;
  top: -0.4rem;
}

.media-icon-item-align-center .media-icon-item-icon svg {
  top: auto;
}

.media-icon-item-icon svg path {
  fill: rgb(var(--color-accent-2));
}

.media-icon-item-content {
  flex: auto;
  min-width: 1px;
  font-size: calc(var(--font-body-scale) * 1.3rem);
}

.media-icon-item-content a {
  color: currentColor;
  text-decoration: underline;
  text-underline-offset: 0.2rem;
  text-decoration-thickness: 0.1rem;
}

.media-icon-item-content a:hover {
  color: rgb(var(--color-accent-1));
}

.media-icon-item-title {
  color: rgb(var(--color-accent-1));
  margin-bottom: .2rem;
}

.media-icon-item-title:only-child {
  margin-bottom: 0;
}

/* Inline media icon items */
.media-icon-items-inline .media-icon-item {
  background: none;
  box-shadow: none;
  border: 0;
  padding: 0;
  color: rgb(var(--color-foreground));
  margin: .75rem 0;
  height: auto;
}

@media (min-width: 990px) {
  .media-icon-items-inline .media-icon-item {
    margin: 0;
  }

  .media-icon-items-inline .media-icon-item-content {
    flex: auto;
  }
}

.media-icon-item-icon-size-small .media-icon-item-icon {
  width: 2.3rem;
  height: 2.3rem;
}

.media-icon-item-icon-size-small svg,
.media-icon-items-inline .media-icon-item-icon svg {
  top: auto;
}

.media-icon-items-inline .media-icon-item-title {
  color: currentColor;
  margin-top: 3px;
  font-size: calc(var(--font-body-scale) * 1.5rem);
}

.media-icon-items-inline .media-icon-item-align-center .media-icon-item-title {
  margin-top: 0;
}

@media (max-width: 989px) {
  .media-icon-items .scroller {
    overflow: hidden;
  }

  .media-icon-items .scroller-inner {
    padding-bottom: 20px;
    margin-bottom: -20px;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
  }

  .scroller-inner .media-icon-items-grid {
    display: flex;
    grid-column-gap: var(--grid-mobile-horizontal-spacing);
    flex-flow: row nowrap;
    padding: 1px 1px 2px 1px;
  }

  .scroller-inner .media-icon-item {
    width: calc(33.333% + 2rem);
    max-width: calc(33.333% + 2rem);
    scroll-snap-align: center;
    flex: none;
  }
}

@media (max-width: 749px) {
  .scroller-inner .media-icon-item {
    width: 85%;
    max-width: 85%;
  }
}
